/* eslint-disable max-lines */
import { AdresseBeneficiaire } from './beneficiaires';
import { Adresse, EtatCivil, FormEntities } from './entities';
import { ProcedureDemandeType } from './procedures';
import { Usager } from './usager';
import { LIEN_PARENTE } from '../../../constants/regroupementFamiliale';

export type BeneficiaireAdresse = {
  id: string | null;
  adresse: Adresse;
};

export type Beneficiaire = FormEntities & {
  rfDemande?: boolean | null;
  vitEnFrance?: boolean | null;
  adresse?: BeneficiaireAdresse | null;
  estValide?: boolean;
  etatCivil?: EtatCivil;
  idRfng?: string | null;
  lienParente?: string;
  uuid?: string | null;
};

export type Conjoint = Beneficiaire & {
  dejaMarie: boolean | null;
  titreSejourFr: boolean | null;
  divorceTrad: boolean | null;
  divorceOriginal?: boolean | null;
};

export type Enfant = Beneficiaire & {
  filiation?: string | null;
  lienSecondParent?: string | null;
  adoptionOriginal?: boolean | null;
  kafalaOriginal?: boolean | null;
  droitGardeOriginal?: boolean | null;
  abandonOriginal?: boolean | null;
  decesConjointOriginal?: boolean | null;
  adoptionTrad?: boolean;
  kafalaTrad?: boolean;
  droitGardeTrad?: boolean;
  decesConjointTrad?: boolean;
  abandonTrad?: boolean;
  autorisationVenuTrad?: boolean;
};

export type Union = FormEntities & {
  uuid?: string;
  type?: string;
  dateDebut?: string;
  dateFin?: string;
  divorceOriginal?: boolean | null;
  divorceTrad?: boolean;
  estValide?: boolean;
};

export type Accomodation = FormEntities & {
  uuid?: string | null;
  adresse?: Adresse;
  principale?: boolean | null;
  adresseNonTrouvee?: boolean | null;
  situation?: string | null;
  typeHebergeur?: string | null;
  hebergeur?: string | null;
  logementDispoDate?: string | null;
  type?: string | null;
};

export type Ressource = {
  id?: string;
  documents?: Document[];
  activite: string | null;
  uuid?: string | null;
  estActuel?: boolean;
  isNew?: string | null;
  sort?: number | null;
};

export type Identity = TitreDeSejour &
  SituationMatrimonial & {
    isValid: boolean;
    isUntouched: boolean;
    etatCivil: EtatCivil;
    situationMatrimonial: string | null | undefined;
    titreDeSejour: string | null | undefined;
    documents: Document[];
  };

export type SituationMatrimonial = {
  situationMatrimonialDate?: string | null | undefined;
  situationMatrimonialOriginal?: boolean | null | undefined;
  situationMatrimonialTrad?: boolean | null | undefined;
  marieEnFrance?: boolean | null | undefined;
};

export type TitreDeSejour = {
  titreDeSejourAutre?: string | null | undefined;
  titreDeSejourFin?: string | null | undefined;
  titreDeSejourMention?: string | null | undefined;
  titreDeSejourMentionAutre?: string | null | undefined;
  titreDeSejourRenouvellement?: boolean | null | undefined;
};

export type Ressources = {
  isValid: boolean;
  isUntouched: boolean;
  activites: Ressource[];
  arretTravail12DernierMois?: boolean | null;
  autresRessources?: boolean | null;
  beneficiaireAahOuAsi?: boolean | null;
  declarerConjointRessources?: boolean | null;
  documents: Document[];
  pensionAlimentaire?: boolean | null;
  travailDansBtp?: boolean | null;
};

export type Commitments = {
  isValid: boolean;
  isUntouched: boolean;
  engagementArt4211?: boolean | null;
  engagementCertifieRenseignementsExact?: boolean | null;
  engagementChangementSituation?: boolean | null;
  engagementDeclareRfNonPolygamie?: boolean | null;
  engagementRgpd?: boolean | null;
  engagementSignatureDocument?: boolean | null;
  haveNumFix: boolean;
  telFixe?: string | null;
  telPortable?: string | null;
  email?: string | null;
};

export type RfInfos = {
  idCerfaRfng?: string | null;
  idRfng?: string | null;
  numeroDossier?: string | null;
  signatureDocumentLieu?: boolean | null;
  statut?: string;
  type?: string;
};

export type Family = {
  isValid: boolean;
  isUntouched: boolean;
  documents: Document[];
  conjoint: Conjoint[];
  enfants: Enfant[];
  unions: Union[];
  adresses?: AdresseBeneficiaire[];
};

export type NullableString = string | null;

export interface DocumentReformat {
  id?: NullableString;
  document: Document | null | undefined;
}

export interface Document {
  id: string | null | undefined;
  categorie: string | null | undefined;
  statut: string | null | undefined;
  nomFichier: string | null | undefined;
  nomFichierPublic: string | null | undefined;
  idRfng: string | null | undefined;
  options: DocumentOptions | null | undefined;
}

export interface DocumentOptions {
  en_francais: boolean;
}

export interface Logement {
  id: string | null;
  uuid?: string | null;
  principale?: boolean | null;
  adresseNonTrouvee?: boolean | null;
  situation?: string | null;
  typeHebergeur?: string | null;
  hebergeur?: string | null;
  logementDispoDate?: string | null;
  adresse?: Adresse;
  documents: Document[];
}

export interface Demande {
  id?: string | null;
  type?: ProcedureDemandeType.DEMANDE_RF;
  path?: string | any;
  usager?: Usager;
  statut?: RFStatut | any;
  clefStatut?: RFClefStatut;
  demandeLogements?: Logement[] | null;
  demandeResources?: Ressource[] | null;
  demandeConjoints?: Conjoint[] | null;
  demandeEnfants?: Enfant[] | null;
  demandeUnions?: Union[] | null;
  demandeAdresses?: AdresseBeneficiaire[] | null;
  travailDansBtp?: boolean | null;
  arretTravail12DernierMois?: boolean | null;
  beneficiaireAahOuAsi?: boolean | null;
  autresRessources?: boolean | null;
  pensionAlimentaire?: boolean | null;
  declarerConjointRessources?: boolean | null;
  signatureDocumentLieu?: boolean | null;
  engagementCertifieRenseignementsExact?: boolean | null;
  engagementChangementSituation?: boolean | null;
  engagementDeclareRfNonPolygamie?: boolean | null;
  engagementArt4211?: boolean | null;
  engagementSignatureDocument?: boolean | null;
  engagementRgpd?: boolean | null;
  documents: Document[];
  numeroDossier?: string | null;
  idCerfaRfng?: string | null;
  idRfng?: string | null;
}

export interface RFDemande {
  beneficiaires: RFBeneficiare[];
  numeroDossier: string;
  adresseDemandeur: string;
  adresseEnqueteDemandeur: string | null;
  directionTerritoriale: string;
  titreSejourValiditeDateDebut: string | null;
  titreSejourValiditeDateFin: string | null;
  clefStatut: RFClefStatut;
  statut: RFStatut;
  dateStatut: string | null;
  numeroAgdref: string;
  responsableStatut: string | null;
  documentsManquant: RFDocumentManquant[];
  documentsIncomplets: RFDocumentIncomplet[];
  enqueteLogement: RFEnqueteLogement | null;
  enqueteRessources: RFEnqueteRessources | null;
  etapes: RFEtape[];
  dateEnvoiConsulat: string | null;
  typeDepot: RFTypeDepot;
  idProcedure: string | null;
  postalStatut: RFPostalStatut | null;
  shouldDisplayEnquete: boolean;
}

export interface RFDemandeContenuApi {
  total: number;
  contenu: RFDemande;
}

export interface RFBeneficiare {
  prenomBeneficiaire: string;
  nomBeneficiaire: string;
  dateNaissance: string | null;
  nationalite: string;
  paysResidence: string;
  lienFamilial: LIEN_PARENTE | null;
}

export interface RFDocumentManquant {
  nomDocument: string;
}

export interface RFDocumentIncomplet {
  nomDocument: string;
}

export interface RFEnqueteLogement {
  statut: RFEnqueteStatut;
  date: string | null;
}

export interface RFEnqueteRessources {
  statut: RFEnqueteStatut;
  motif: RFEnqueteRessourcesMotif | null;
  date: string | null;
}

export enum RFStatut {
  RECEPTIONNE = 'receptionne',
  RECEPTIONNE_ATTENTE_RETOUR_PREFECTURE = 'receptionne_attente_retour_prefecture',
  RECEPTIONNE_ATTENTE_DOCUMENTS_COMPLEMENTAIRES = 'receptionne_attente_documents_complementaires',
  EN_COURS_INSTRUCTION = 'en_cours_instruction',
  ATTENTE_DECISION_PREFECTURE = 'attente_decision_prefecture',
  DECISION_ATTENTE_RETOUR_PREFECTURE = 'decision_attente_retour_prefecture',
  FAVORABLE = 'favorable',
  FAVORABLE_ATTENTE_PAIEMENT = 'favorable_attente_paiement',
  FAVORABLE_RELANCE_PAIEMENT = 'favorable_relance_paiement',
  DEFAVORABLE = 'defavorable',
  DOSSIER_CLOTURE = 'dossier_cloture',
  EN_COURS_DE_DEPOT = 'en_cours_de_depot',
}

export enum RFClefStatut {
  RECEPTIONNE_RECEPTIONNE_DOCUMENT_RECU = 'receptionne__receptionne_document_recu',
  RECEPTIONNE_RECEPTIONNE = 'receptionne__receptionne',
  RECEPTIONNE_RECEPTIONNE_ATTENTE_RETOUR_PREFECTURE = 'receptionne__receptionne_attente_retour_prefecture',
  RECEPTIONNE_RECEPTIONNE_ATTENTE_DOCUMENTS_COMPLEMENTAIRES = 'receptionne__receptionne_attente_documents_complementaires',
  EN_COURS_INSTRUCTION_EN_COURS_INSTRUCTION = 'en_cours_instruction__en_cours_instruction',
  ATTENTE_DECISION_PREFECTURE_ATTENTE_DECISION_PREFECTURE = 'attente_decision_prefecture__attente_decision_prefecture',
  DECISION_DECISION_ATTENTE_RETOUR_PREFECTURE = 'decision__decision_attente_retour_prefecture',
  DECISION_FAVORABLE = 'decision__favorable',
  DECISION_FAVORABLE_ATTENTE_PAIEMENT = 'decision__favorable_attente_paiement',
  DECISION_FAVORABLE_RELANCE_PAIEMENT = 'decision__favorable_relance_paiement',
  DECISION_DEFAVORABLE = 'decision__defavorable',
  PAS_ETAPE_DOSSIER_CLOTURE_DEMANDE_INTERRESSE = 'pas_etape__dossier_cloture__demande_interresse',
  PAS_ETAPE_DOSSIER_CLOTURE_ACQUISITION_NATIONNALITE_FRANCAISE = 'pas_etape__dossier_cloture__acquisition_nationnalite_francaise',
  PAS_ETAPE_DOSSIER_CLOTURE_DEMENAGEMENT_DEPARTEMENT = 'pas_etape__dossier_cloture__demenagement_departement',
  PAS_ETAPE_DOSSIER_CLOTURE_DT_NON_COMPETENTE = 'pas_etape__dossier_cloture__dt_non_competente',
  PAS_ETAPE_DOSSIER_CLOTURE_DOSSIER_INCOMPLET = 'pas_etape__dossier_cloture__dossier_incomplet',
  PAS_ETAPE_DOSSIER_CLOTURE = 'pas_etape__dossier_cloture',
  PAS_ETAPE_DOSSIER_CLOTURE_DOSSIER_IRRECEVABLE = 'pas_etape__dossier_cloture__dossier_irrecevable',
  MOTIF = 'motif',
}

export enum RFEnqueteStatut {
  EN_COURS = 'en_cours',
  TERMINEE = 'terminee',
  SANS_SUITE = 'sans_suite',
  DISPENSEE = 'dispensee',
}

export enum RFEnqueteRessourcesMotif {
  ALLOCATION_ADULTES_HANDICAPES = 'allocation_adultes_handicapes',
  PLUS_65_ANS = 'plus_65_ans',
  ALLOCATION_SUPPLEMENTAIRE_INVALIDITE = 'allocation_supplementaire_invalidite',
}

export interface RFEtape {
  statut: RFEtapeStatut;
  nom: RFEtapeNom;
  numero: number;
  date: Date | null;
}

export enum RFEtapeNom {
  RECEPTIONNEE = 'receptionnee',
  EN_COURS_INSTRUCTION = 'en_cours_instruction',
  EN_ATTENTE_DECISION = 'en_attente_decision',
  DECISION = 'decision',
}

export enum RFTypeDepot {
  PORTAIL = 'portail',
  POSTAL = 'postal',
}

export enum RFPostalStatut {
  DOSSIER_CLOTURE = 'dossier_cloture',
  EN_COURS = 'en_cours',
}

export interface RFDemandeSummary {
  type: ProcedureDemandeType.REGROUPEMENT_FAMILIAL;
  path: string;
  numeroDossier: string | null;
  dateStatut: Date | null;
  clefStatut: RFClefStatut | null;
  statut: RFStatut | null;
  typeDepot: RFTypeDepot | null;
  idProcedure: string | null;
  postalStatut: RFPostalStatut | null;
}

export enum RFEtapeStatut {
  A_VENIR = 'a_venir',
  EN_COURS = 'en_cours',
  TERMINE = 'termine',
  EN_ATTENTE_USAGER = 'en_attente_usager',
  REFUSEE = 'refusee',
  DOSSIER_CLOTURE = 'dossier_cloture',
}

export type RFBadges = Exclude<RFEtapeStatut, RFEtapeStatut.DOSSIER_CLOTURE>;

export enum RFLienFamilial {
  EPOUX = 'epoux',
  ENFANT = 'enfant',
}

// export interface RFBeneficiare {
//   prenomBeneficiaire: string;
//   nomBeneficiaire: string;
//   dateNaissance: Date | null;
//   nationalite: string;
//   paysResidence: string;
//   lienFamilial: RFLienFamilial | null;
// }
